/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Utils from '../../utils'
import * as style from './index.module.less'

const About = ({ data: { profilePhoto, flagIt, skillIcons, dotnetSkillIcons, nodeJSSkillIcons, languageSkillIcons, frontendSkillIcons, databaseSkillIcons, webscrapingSkillIcons, apiSkillIcons, toolIcons } }) => {
  return (
    <Layout>
      <SEO
        title="About"
        description="A brief summary of this blog and my work"
        path="about"
      />
      <div className={style.container}>
{/*         <div className={style.photo}>
          <Img fluid={profilePhoto.childImageSharp.fluid} />
  </div> */}
        <div className={style.content}>
          <h1>Hi, I'm Aravind Hande!</h1>
          <h2>Software Developer</h2>
          <p>
            I am a full stack web developer specializing mostly in JavaScript/Node.js/Express/React but also work with Microsoft .NET Core, ASP.NET MVC, Python and Web scraping using Node and Python.
            {/*             Over 25 years of software development experience and over 20 years
            designing, architecting and developing web applications. I am a full-stack developer skilled in ASP.NET, ASP.NET
            Core, MVC, Entity Framework Core, SQL Server, Microsoft .NET Framework,
            HTML, CSS, JavaScript, JQuery, XAML (Silverlight), XML, XSL, REST,
            ODATA and GraphQL API Services. I am also a full-stack developer
            proficient in Firebase / Firestore, MongoDB, Mongoose, Node.JS,
            Express, React and Angular (MEAN / MERN stack). I enjoy taking on
            new challenges and constantly strive to learn new skills. */}
          </p>
          <br />


          <h2>Skills</h2>
          <h2>Node</h2>
          <ImageList edges={nodeJSSkillIcons.edges} />
{/*           <h2>Programming / Scripting / Markup Languages</h2>
          <ImageList edges={languageSkillIcons.edges} /> */}
          <h2>Databases (SQL, NoSQL, ORMs)</h2>
          <ImageList edges={databaseSkillIcons.edges} />
          <h2>Front End Frameworks / Libraries</h2>
          <ImageList edges={frontendSkillIcons.edges} />
          <h2>Web Scraping</h2>
          <ImageList edges={webscrapingSkillIcons.edges} />     
          <h2>API Development (Microsoft .NET & Node/Express)</h2>
          <ImageList edges={apiSkillIcons.edges} /> 
          <h2>Microsoft .NET</h2>
          <ImageList edges={dotnetSkillIcons.edges} />

{/*           <h2>Tools / Editors</h2>
          <ImageList edges={toolIcons.edges} /> */}
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    flagIt: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    skillIcons: PropTypes.object.isRequired,
    dotnetSkillIcons: PropTypes.object.isRequired,    
    nodeJSSkillIcons: PropTypes.object.isRequired,
    languageSkillIcons: PropTypes.object.isRequired,    
    databaseSkillIcons: PropTypes.object.isRequired,
    frontendSkillIcons: PropTypes.object.isRequired,    
    webscrapingSkillIcons: PropTypes.object.isRequired,    
    apiSkillIcons: PropTypes.object.isRequired,    
    toolIcons: PropTypes.object.isRequired,
  }),
}

const ImageList = ({ edges }) => (
  <div className={style.iconsContainer}>
    {edges
      .sort((edgeA, edgeB) =>
        edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase() ? 1 : -1
      )
      .map(({ node: { name, childImageSharp } }) => (
        <div className={style.iconWrapper} key={name}>
          <Img
            fixed={childImageSharp.fixed}
            alt={name + ' logo'}
            title={name}
          />
          <label>
            {iconsNameMap[name] ? iconsNameMap[name] : Utils.capitalize(name)}
          </label>
        </div>
      ))}
  </div>
)

ImageList.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export const query = graphql`
  {
    profilePhoto: file(name: { eq: "profile-photo" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    flagIt: file(name: { eq: "flag-it" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    skillIcons: allFile(filter: { dir: { regex: "/about/skills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    dotnetSkillIcons: allFile(filter: { dir: { regex: "/about/dotnetskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    nodeJSSkillIcons: allFile(filter: { dir: { regex: "/about/nodejsskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    languageSkillIcons: allFile(filter: { dir: { regex: "/about/languageskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    databaseSkillIcons: allFile(filter: { dir: { regex: "/about/databaseskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    frontendSkillIcons: allFile(filter: { dir: { regex: "/about/frontendskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    webscrapingSkillIcons: allFile(filter: { dir: { regex: "/about/webscrapingskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    apiSkillIcons: allFile(filter: { dir: { regex: "/about/apiskills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }    
    toolIcons: allFile(filter: { dir: { regex: "/about/tools$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`
// Use to set specific icons names
const iconsNameMap = {
  css: 'CSS',
  html: 'HTML',
  jquery: 'JQuery',
  nodejs: 'Node.js',
  vuejs: 'Vue.js',
  gruntjs: 'Grunt.js',
  efcore: 'EF Core',
  nodejsscraping: 'Node.js',
  pythonscraping: 'Python',
  vbnet: 'VB .NET',
  vs2019: 'Visual Studio',
  beautifulsoup: 'Beautiful Soup',
  nextjs: 'Next.js',
  nuxt: 'Nuxt.js',
  dotnetcore: '.NET Core',
  aspnet: 'ASP.NET MVC'
}

export default About
